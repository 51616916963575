import { render, staticRenderFns } from "./Base.vue?vue&type=template&id=38ebe98b&scoped=true"
import script from "./Base.vue?vue&type=script&lang=ts"
export * from "./Base.vue?vue&type=script&lang=ts"
import style0 from "./Base.vue?vue&type=style&index=0&id=38ebe98b&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38ebe98b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_34f2f136/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38ebe98b')) {
      api.createRecord('38ebe98b', component.options)
    } else {
      api.reload('38ebe98b', component.options)
    }
    module.hot.accept("./Base.vue?vue&type=template&id=38ebe98b&scoped=true", function () {
      api.rerender('38ebe98b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Base.vue"
export default component.exports